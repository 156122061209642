<template>
  <div class="container">
    <div class="head">
      <img src="@/assets/bg_new_guide_video_nvip_area.webp" width="200" alt="">
    </div>
    <div class="center">
      <h1>扫码系统</h1>
      <el-row style="margin-bottom: 20px">
        <el-button round style="width: 250px" type="primary" @click="$router.push('/admin');">登录后台</el-button>
      </el-row>
      <el-row style="margin-bottom: 20px">
        <el-button round style="width: 250px" type="success" @click="openShopLink">部署系统</el-button>
      </el-row>

      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="系统什么语言写的?" name="1">
          <div>GO+VUE 做什么都快人一步!</div>
          <div>采用当今最流行先进的框架</div>
        </el-collapse-item>
        <el-collapse-item title="系统好部署吗?" name="2">
          <div>非常简单容易部署、支持一键部署</div>
          <div>简单的只需要一步! 拒绝废物PHP操作麻烦部署繁琐</div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ['1']
    }
  },
  methods: {
    openShopLink() {
      window.open('http://shop.luatig.top/', '_blank');
    }
  }
}
</script>

<style scoped lang="less">
.container {
  max-width: 380px;
  margin: 0 auto;
  text-align: center;

  .head {
    background-image: url("@/assets/svg/bbburst.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100%;
  }
}
</style>