import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'; // 导入NProgress样式文件
import Layout from '@/views/layout/Index.vue'
import Home from '@/views/home/Index.vue'

Vue.use(VueRouter)

NProgress.configure({
    easing: 'ease-in-out',
    speed: 500,
    trickle: true,
    trickleSpeed: 200,
    showSpinner: false
});

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Layout,
        redirect: '/home',
        children: [
            {
                path: '/home',
                component: Home
            },
            {
                path: '/qrlogin',
                component: () => import('@/views/qrLogin/Index.vue'),
            },
        ]
    },
    {
        path: '/admin',
        component: () => import('@/views/admin/Index.vue'),
        meta: {requiresAuth: true},
        children: [
            {
                path: '',
                redirect: 'dashboard' // 默认重定向到dashboard
            },
            {
                path: 'dashboard',
                component: () => import('@/views/admin/dashboard.vue')
            },
            {
                path: 'setting',
                component: () => import('@/views/admin/settings.vue'),
                props: route => ({
                    tab: route.query.tab,
                }) // 将路由参数传递给组件的props
            },
            {
                path: 'cardManage',
                component: () => import('@/views/admin/cardManage.vue')
            },
            {
                path: 'accountList',
                component: () => import('@/views/admin/accountList.vue')
            },
        ]
    },
    {
        path: '/login',
        component: () => import('@/views/login/Index.vue')
    },
    {
        path: '/register',
        component: () => import('@/views/login/register.vue')
    }, {
        path: '/forgetpassword',
        component: () => import('@/views/login/forgetpassword.vue')
    }
]

const router = new VueRouter({
    routes
})


// 获取token
function isAuthenticated() {
    return localStorage.getItem('token') !== null;
}

// 导航守卫
router.beforeEach((to, from, next) => {
    NProgress.start();
    // 检查是否需要登录才能访问的路由
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated()) {
            NProgress.done();
            next('/login');
        } else {
            next();
        }
    } else {
        // 如果路由不需要登录，直接允许访问
        next();
    }
});

router.afterEach(() => {
    // 结束进度条
    NProgress.done();
});


export default router
