import axios from 'axios'

let routerInstance; // 存储Vue Router实例

// 设置Vue Router实例的方法
export function setRouter(router) {
    routerInstance = router;
}

const request = axios.create({
    timeout: 5000 // 请求超时时间
})

// 添加请求拦截器
request.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = token;
    }
    return config
}, error => {
    return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(response => {
    try {
        if (response.data.error === 302) {
            routerInstance.push(response.data.url)
            console.log("重定向了")
            return
        }
    } catch (e) {
        console.log("重定向失败：" + e)
    }
    return response
}, error => {
    return Promise.reject(error)
})

export default request