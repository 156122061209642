<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>


<script>
import Vue from 'vue'
import Meta from 'vue-meta'

Vue.use(Meta)

export default {
  name: 'App',
  metaInfo: {
    title: '百度网盘扫码系统',
    meta: [
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
      },
      {
        name: 'keywords',
        content: '百度网盘扫码系统, 百度网盘, 百度网盘共享账号'
      }
    ]
  }
}
</script>